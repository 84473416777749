"use client";

import { useRouter } from "next/navigation";
import { Button } from "ui/components/buttons";
import { HiOutlineBuildingLibrary } from "ui/react-icons/hi2";

export default function MainTile() {
  const router = useRouter();

  return (
    <div id="presentation-page" className="page mt-0">
      <div className="tile pb-3">
        <div id="icon" className="mt-3">
          <HiOutlineBuildingLibrary size={100} color={"var(--color-text)"} />
        </div>

        {/* <AutoConnect /> */}
        <h2 className="text-center">Espace Cabinet</h2>
        <p className="text-center">Bienvenue chez Syndemos, cliquez sur le bouton ci-dessous pour vous connecter</p>

        <Button data-test="begin-btn" id="begin-btn" icon="door" type="dark" cb={() => router.push("/auth/connexion")}>
          Commencer
        </Button>
      </div>
    </div>
  );
}
