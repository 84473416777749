"use client";
import MainTile from "@/components/MainTile";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import type { JSX } from "react";
import Header from "./_components/Header";
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY);

export default function Home(): JSX.Element {
  //
  return (
    <Elements stripe={stripePromise}>
      <Header />
      <MainTile />
    </Elements>
  );
}
